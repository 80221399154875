import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomDataSource, Page} from './CustomDataSource';
import { SiteService } from '../services/site.service';

export class UploadHistoryDataSource extends CustomDataSource<Requests> {

  public fileUploadData = {
    "requests": [
      {
        "requestId": "8a7b9c00-1234-4d56-9abc-def012345678",
        "tenantId": "f0d399b0-59a1-45bc-8ccc-51fba4bbc037",
        "fileType": "MSR",
        "timestamp": "2024-11-25T14:30:00Z",
        "files": [
          { 
             "fileName": "file-5.csv", 
             "fileId": "773bd437-b636-4f8c-9438-39bf4dd383b2", 
             "totalRecords":968,
             "status": "Malware_Scanning",
          },
          {
              "fileName": "file-6.csv",
              "fileId": "d2275f65-d206-4987-b391-c1e41d36f061",
              "totalRecords":614,
              "status": "Size_Validating",
          }
        ]
      },
      {
        "requestId": "f23ac44e-5678-1234-8abc-9deff678abcd",
        "tenantId": "f0d399b0-59a1-45bc-8ccc-51fba4bbc037",
        "fileType": "SITE_LABEL",
        "timestamp": "2024-11-26T14:30:00Z",
        "files": [
         { 
             "fileName": "file-7.txt", 
             "fileId": "edea65ec-5a95-4f2f-bb18-0f98031f554c", 
             "totalRecords":"Not Applicable",
             "status": "File_Uploaded",
          },
          { 
             "fileName": "file-8.csv", 
             "fileId": "f531ba3f-9b48-4031-b356-e50fd243c9f5", 
             "totalRecords":"500",
             "status": "File Empty"
          }
        ]
      },
      {
        "requestId": "f23ac44e-5678-1234-8abc-9deff678abcd",
        "tenantId": "f0d399b0-59a1-45bc-8ccc-51fba4bbc037",
        "fileType": "CUSTOMER",
        "timestamp": "2024-11-27T10:30:00Z",
        "files": [
         { 
             "fileName": "file-9.txt", 
             "fileId": "edea65ec-5a95-4f2f-bb18-0f98031f554c", 
             "totalRecords":"1",
             "status": "File_Uploaded"
          },
          { 
             "fileName": "file-10.csv", 
             "fileId": "f531ba3f-9b48-4031-b356-e50fd243c9f5", 
             "totalRecords":"22",
             "status": "File_Uploaded"
          },
          { 
            "fileName": "file-11.csv", 
            "fileId": "f531ba3f-9b48-4031-b356-e50fd243c9f5", 
            "totalRecords":"21",
            "status": "File_Uploaded"
         },
         { 
          "fileName": "file-12.csv", 
          "fileId": "f531ba3f-9b48-4031-b356-e50fd243c9f5", 
          "totalRecords":"500",
          "status": "File_Uploaded"
       }
        ]
      }
    ]
  }

  constructor(private siteService: SiteService) {
    super();
  }

  loadData(): Observable<Page<Requests>> {
    let observable: Observable<any>;
    // observable = this.siteService.getUploadHistory(options.buId, page, size, sortField, sortDir);
    observable = of(this.fileUploadData);
    return observable.pipe(map(res => ({data: res.requests, total: res.requests.length})));
  }
}

export interface Requests {
  requestId: string;
  tenantId: string;
  fileType: string;
  timeStamp: Date;
  files: uploadedFile[];
}

export interface uploadedFile {
  fileName: string;
  fileId: string;
  totalRecords: string;
  status: string;
  timeStamp: Date;
}