import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SiteService } from '../../../../services/site.service';
import { ToasterService } from '../../../../services/toaster.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UploadHistoryDataSource } from 'src/app/misc/UploadHistoryDataSource';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { UploadManagerComponent } from '../upload-manager/upload-manager';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'upload-history-list',
    templateUrl: './upload-history-list.component.html',
    styleUrls: ['./upload-history-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UploadHistoryListComponent implements OnInit, AfterViewInit {

    loading = false;
    displayedColumns: string[];
    dataSource: UploadHistoryDataSource;
    data$: any;
    today = (new Date()).toISOString().substring(0,10);
    dateRangeOptions = [
      { label: 'All', value: 'all' },
      { label: 'One Day', value: 'one-day' },
      { label: 'Last Week', value: 'last-week' },
      { label: 'One Month', value: 'one-month' },
      { label: 'Three Months', value: 'three-months' },
      { label: 'Six Months', value: 'six-months' },
      { label: 'One Year', value: 'one-year' },
    ];
  
    selectedRange = 'all';
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    

    constructor(private spinner: NgxSpinnerService, private router: Router, private siteService: SiteService, private toaster: ToasterService, 
        private fileUploadService: FileUploadService, private dialog: MatDialog) {
        this.dataSource = new UploadHistoryDataSource(siteService);
        this.dataSource.loadData().subscribe(res => {
            this.data$ = res.data;
        });
    }

    ngOnInit(): void {
        this.displayedColumns = ['id', 'createdAt', 'fileCount', 'fileType', 'status', 'action'];
    }

    ngAfterViewInit(): void {
        this.dataSource.init(this.paginator, this.sort);
    }

    openDialog(files: any[], timestamp: string) {
        const dialogRef = this.dialog.open(UploadManagerComponent, {
          height: '90%',
          width: '100%',
          panelClass: 'custom-dialog-container',
          autoFocus:false,
          data: {files: files, timestamp: timestamp}
        });
    }

    onSearch(search: string | Event): void {
      if (typeof search === 'string') {
        this.dataSource.search = search;
      } else {
        // @ts-ignore
        this.dataSource.search = search?.target?.value ?? null;
      }
    }

    applyDateFilter() {
      const today = new Date();
      let filteredData;

      this.dataSource.loadData().subscribe(res => {
        this.data$ = res.data;
    });
  
      switch (this.selectedRange) {
        case 'all':
          filteredData = this.data$;
          break;
        case 'one-day':
          console.log("One day");
          filteredData = this.data$.filter((item) => 
            this.isWithinDateRange(new Date(item.timestamp), 1)
          );
          console.log("filtered Data: ", filteredData);
          break;
        case 'last-week':
          filteredData = this.data$.filter((item) =>
            this.isWithinDateRange(new Date(item.timestamp), 7)
          );
          break;
        case 'one-month':
          filteredData = this.data$.filter((item) =>
            this.isWithinDateRange(new Date(item.timestamp), 30)
          );
          break;
        case 'three-months':
          filteredData = this.data$.filter((item) =>
            this.isWithinDateRange(new Date(item.timestamp), 90)
          );
          break;
        case 'six-months':
          filteredData = this.data$.filter((item) =>
            this.isWithinDateRange(new Date(item.timestamp), 180)
          );
          break;
        case 'one-year':
          filteredData = this.data$.filter((item) =>
            this.isWithinDateRange(new Date(item.timestamp), 365)
          );
          break;
        default:
          filteredData = this.data$;
          break;
      }
      console.log("Finish");
      this.data$ = filteredData;
      console.log(this.data$);
    }

    isWithinDateRange(date: Date, days: number): boolean {
      const today = new Date();
      const targetDate = new Date(today);
      targetDate.setDate(today.getDate() - days);
      return new Date(date) >= targetDate;
    }
      
}
