import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BusinessUnitService} from 'src/app/services/business-unit.service';
import {AuthService} from '../../../auth/services/auth.service';
import {BusinessUnitDataSource} from '../../../misc/BusinessUnitDataSource';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadComponent } from 'src/app/components/common/file-upload/file-upload.component';

@Component({
  selector: 'app-tenants-business-unit',
  templateUrl: './tenants-business-unit.component.html',
  styleUrls: ['./tenants-business-unit.component.scss']
})
export class TenantsBusinessUnitComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['name', 'id', 'createdAt', 'isActive', 'action'];
  @Input() tenantId;
  // dataSource:any
  dataSource: BusinessUnitDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  admin: Boolean = this.authService.getFromCookie("admin") == "true";


  constructor(private router:Router, private businessUnitService:BusinessUnitService, private authService: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
    // this.getBusinessUnit();
    this.dataSource = new BusinessUnitDataSource(this.businessUnitService, this.authService);
  }

  ngAfterViewInit(): void {
    this.dataSource.init(this.paginator, this.sort);
  }
  getBusinessUnit(){
    //need to implement api integration here
    this.businessUnitService.findAllBusinessUnitsByTenant(this.tenantId).subscribe(
      (res:any)=>{
        this.dataSource=res.businessUnits;
      }
    )
  }

  openDialog() {
    const dialogRef = this.dialog.open(FileUploadComponent, {
      height: '80%',
      width: '70%',
      panelClass: 'custom-dialog-container',
      autoFocus:false
    });
  }

  onSearch(search: string | Event): void {
    if (typeof search === 'string') {
      this.dataSource.search = search;
    } else {
      // @ts-ignore
      this.dataSource.search = search?.target?.value ?? null;
    }
  }

  onClickAdd(){
    this.router.navigate([`/tenants/${this.tenantId}/business-units/new`]);
  }

  viewTenantsBu(businessUnit:any){
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${businessUnit.id}/view`]);

  }

  editTenantsBu(businessUnit:any){
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${businessUnit.id}/edit`]);;
  }
}
