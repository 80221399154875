<h2 mat-dialog-title style="text-align: center;"> Upload File </h2>
<h5 mat-dialog-title style="text-align: center; color: red;">Only supports csv and txt.</h5>
<h5 mat-dialog-title style="text-align: center; color: red;">Max number of files is {{maxFileNum}}.</h5>


<div class="container" appDnd (fileDropped)="onFileDropped($event)">
  <h3> Drag and drop file here </h3>
  <h3> or </h3>
  <input type="file" #fileBrowse id="fileBrowse" multiple accept=".txt, .csv" (change)="fileBrowseHandler($event.target.files)" [max]="15" />
  <label for="fileBrowse"> Browse for file </label>
</div>

<h5 style="text-align: center; margin-top: auto; margin-bottom: auto; color: #333333">
  <strong> {{selectedFiles.length}} </strong> files added.
</h5>

<mat-dialog-content>
  <table [dataSource]="dataSource" class="w-100 upload-table" mat-table>
    <ng-container matColumnDef="name">
      <th style="width: 150px;text-align: left;" mat-header-cell *matHeaderCellDef>File Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th style="width: 70px;text-align: center;" mat-header-cell *matHeaderCellDef>File Size</th>
      <td style="width: 50px;text-align: center;" mat-cell *matCellDef="let element"> {{formatBytes(element.size)}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th style="width: 70px;text-align: center;" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let i = index" style="text-align: center;">
        <button mat-icon-button (click)="deleteFile(i)">
          <img src="assets/images/delete.svg" width="20px">
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <button [mat-dialog-close]="true" (click)="upload()" class="rounded-pill small" color="primary" mat-flat-button type="button"
  [disabled]="uploadButton"> Upload </button>
</mat-dialog-actions>


<ngx-spinner
    bdColor="rgba(0,0,0,0.1)"
    size="medium"
    color="#00539E"
    type="ball-clip-rotate"
  ></ngx-spinner>
