import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteService } from '../../../services/site.service';
import { ToasterService } from '../../../services/toaster.service';
import { MatTableDataSource } from '@angular/material/table';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { MAX_FILE_NUM } from 'src/app/app.constants';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    @Input()
    placeholder: string;

    fileName: string = "";
    selectedFiles: any[] = [];
    loading: boolean = false;
    displayedColumns: string[] = ['name', 'size', 'action'];
    dataSource = new MatTableDataSource(this.selectedFiles);
    uploadButton: boolean = true;
    maxFileNum = MAX_FILE_NUM;

    constructor(private spinner: NgxSpinnerService, 
        private toaster: ToasterService,
        private siteService: SiteService,
        private route: ActivatedRoute,
        private router: Router,
        private uploadService: FileUploadService){

    }

    ngOnInit(): void {
    }

    clear() {
        this.selectedFiles = null;
        this.fileName = null;
    }

    onFileDropped($event) {
        // for (const item of ev) {
        //     this.selectedFile.push(item);
        // }
        // // this.fileName = this.selectedFile.name
        // this.onFileSelected.emit(this.selectedFile)
        this.prepareFilesList($event);
    }

    fileBrowseHandler(files) {
        this.prepareFilesList(files);
        this.uploadButtonToggle();
    }

    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            item.progress = 0;
            this.selectedFiles.push({
                name: item.name,
                size: item.size
            });
        }
        this.dataSource._updateChangeSubscription();
    }

    uploadButtonToggle() {
        if (this.dataSource.data.length <= MAX_FILE_NUM && this.dataSource.data.length > 0) {
            this.uploadButton = false;
        } else {
            this.uploadButton = true;
        }
    }

    formatBytes(bytes, decimals) {
        if (bytes === 0) {
          return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    deleteFile(index: number) {
        this.selectedFiles.splice(index, 1);
        this.dataSource._updateChangeSubscription();
        this.uploadButtonToggle();
    }

    // TODO: Add in upload backend
    upload() {
        console.log("Files to be uploaded: ", this.selectedFiles);
        console.log("Upload files");
        this.uploadService.changeFiles(this.selectedFiles);
    }
}
