<div class="card-container" [hidden]="filesUploaded.length == 0" id="card">
    <div class="summary">
      <div class="text-content">
        <div>
          <span><strong> {{success}}</strong> of <strong> {{ filesUploaded.files.length}}</strong> files <strong> uploaded</strong></span>
        </div>
        <div>
          <span *ngIf="failed != 0" style="color: red;">{{failed}} files failed.</span>
        </div>
      </div>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close (click)="close()">
        <img src="assets/images/x.svg" width="30px" style="color: #00539E;">
      </button>
    </div>

    <button class="toggle-button" (click)="collapsed = !collapsed">
      <mat-icon class="arrow">
        {{ collapsed ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
      </mat-icon>
    </button>
    <mat-card *ngIf="!collapsed" class="more-details">
  <mat-card-content *ngFor="let element of filesUploaded.files">
    <div>
      <span class="text-content">
        <h3>{{element.fileName}}</h3>
        <i class="material-icons success" *ngIf="element.status == 'File_Uploaded'" style="color: green;">check_circle</i>
        <h4 *ngIf="updating_array.includes(element.status)" style="color: #00539E;">{{element.status}} . . .</h4>
      </span>
      <span *ngIf="!updating_array.includes(element.status) && element.status != 'File_Uploaded'" class="status-container">
        <span class="failed-box">Failed</span>
        <h5>{{element.status}}</h5>
      </span>
    </div>
  </mat-card-content>
</mat-card>

  </div>
  