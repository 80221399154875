import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SiteService } from '../../../../services/site.service';
import { ToasterService } from '../../../../services/toaster.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Requests, uploadedFile, UploadHistoryDataSource } from 'src/app/misc/UploadHistoryDataSource';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UPLOADING_STATUS } from 'src/app/app.constants';

@Component({
    selector: 'upload-manager',
    templateUrl: './upload-manager.html',
    styleUrls: ['./upload-manager.scss']
})
export class UploadManagerComponent implements OnInit, AfterViewInit {

    loading = false;
    displayedColumns: string[];
    dataSource: UploadHistoryDataSource;
    data$: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private spinner: NgxSpinnerService, private router: Router, private siteService: SiteService, 
      private toaster: ToasterService, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dataSource = new UploadHistoryDataSource(data.files);
    }

    ngOnInit(): void {
        this.displayedColumns = ['id', 'name', 'fileType', 'createdAt', 'totalCount', 'uploadStatus', 'progressStatus', 'action'];
        this.dataSource.loadData().subscribe(res => {
          this.data$ = res.data;
      });;
    }

    ngAfterViewInit(): void {
        this.dataSource.init(this.paginator, this.sort);
    }

    download(fileId: string) {
        this.spinner.show()
        this.siteService.downloadCSV(fileId).subscribe(
            res => {
                if(res && res.file) {
                    window.open(res.file, '_blank');
                } else {
                    this.toaster.warning("File cannot be downloaded")
                }
            },
            err => {
                if (err?.error?.message) {
                    this.toaster.error(err.error.message.split(',').join('\n'));
                  } else {
                    this.toaster.error('Something went wrong. Please try again.');
                  }
            }).add(() => {
            this.spinner.hide();
        });
    }

    fileFormat(name: string) {
      if (name.toLowerCase().includes("csv")) {
        return "CSV";
      } else if (name.toLowerCase().includes("txt")) {
        return "TXT";
      }
    }

    uploadProgress(status: string) {
      if (UPLOADING_STATUS.includes(status)) {
        return true;
      } else {
        return false;
      }
    }
}
