import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/services/auth.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { REST_ENDPOINT } from "../app.constants";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private baseUrl = environment.fileIngestionUrl;

  private files = new BehaviorSubject<any>([]);
  currentFiles = this.files.asObservable();

  private statusCard = new BehaviorSubject<boolean>(false);
  statusCardVisibility$ = this.statusCard.asObservable();

  private refreshToggle = new Subject<void>();
  refresh$ = this.refreshToggle.asObservable();

  private request_id = new BehaviorSubject<string>('');
  requestId$ = this.request_id.asObservable();

  constructor(private httpClient: HttpClient, private authService: AuthService) {
  }

  triggerRefresh() {
    this.refreshToggle.next();
  }

  showStatusCard(visible: boolean) {
    this.statusCard.next(visible);
  }

  changeFiles(newFiles: File[]) {
    this.files.next(newFiles);
  }

  setRequestId(id: string) {
    this.request_id.next(id);
  }

  getAllFiles(username: string): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.files.getAllFiles}${username}`;
    // const url = `${this.external}${REST_ENDPOINT.files.getAllFiles}${username}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptionsForFileUpload());
  }
  
  getStatusByRequestId(requestId: string): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.files.getStatusByRequestId}?requestId=${requestId}`;
    // const url = `${this.external}${REST_ENDPOINT.files.getStatusByRequestId}?requestId=${requestId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptionsForFileUpload());
  }

  getStatusByFileId(fileId: string): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.files.getStatusByFileId}?fileId=${fileId}`;
    // const url = `${this.external}${REST_ENDPOINT.files.getStatusByFileId}?fileId=${fileId}`;
    return this.httpClient.get(url, this.authService.getAuthHttpOptionsForFileUpload());
  }

  uploadFiles(fileType, tenantId, delimiter, formdata): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.files.upload}?fileType=${fileType}&tenantId=${tenantId}&delimiter=${delimiter}`;
    // const url = `${this.external}${REST_ENDPOINT.files.upload}?fileType=${fileType}&tenantId=${tenantId}&delimiter=${delimiter}`;
    return this.httpClient.post(url, formdata, this.authService.getAuthHttpOptionsForFileUpload());
  }

  getStatusByUsername(username: string): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.files.getStatusByUsername}?username=${username}`;
    // const url = `${this.external}${REST_ENDPOINT.files.getStatusByUsername}?username=${username}`;

    return this.httpClient.get(url, this.authService.getAuthHttpOptionsForFileUpload());
  }

  getFileType(fileType: string): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.files.getFileType}?fileType=${fileType}`;
    // const url = `${this.external}${REST_ENDPOINT.files.getFileType}?fileType=${fileType}`;

    return this.httpClient.get(url, this.authService.getAuthHttpOptionsForFileUploadJSON());
  }

  downloadFile(fileId: string): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.files.download}${fileId}`;
    // const url = `${this.external}${REST_ENDPOINT.files.download}${fileId}`;
    const authToken = this.authService.getTokenFromCookie();
    const bearerToken = `Bearer ${authToken}`;
    const tenantId = this.authService.getTenantIdFromCookie();

    const httpOptions : Object = {
      headers: new HttpHeaders({
        'Authorization': bearerToken,
        'tenant': tenantId,
        'Content-Type': 'application/octet-stream'
      }),
      responseType: 'blob',
      observe: 'response'
    };

    return this.httpClient.get(url, httpOptions); 
  }

  getAllFileTypes(): Observable<any> {
    const url = `${this.baseUrl}${REST_ENDPOINT.files.getAllFileTypes}`;
    // const url = `${this.external}${REST_ENDPOINT.files.getAllFileTypes}`;

    return this.httpClient.get(url, this.authService.getAuthHttpOptionsForFileUpload());
  }
}
  