import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/services/auth.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class FileUploadService {
  
    private baseUrl = environment.apigeeLocationUrl;

    private files = new BehaviorSubject<File[]>([]);
    currentFiles = this.files.asObservable();
  
    constructor(private httpClient: HttpClient, private authService: AuthService) {
    }

    
    changeFiles(newFiles: File[]) {
      this.files.next(newFiles);
    }

    // TODO: getAllFiles endpoint

    // TODO: uploadFiles endpoint

    // TODO: downloadFiles endpoint
}
  