import {Component, HostBinding, Input, OnInit} from '@angular/core';
import { UPLOADING_STATUS } from 'src/app/app.constants';
import { FileUploadService } from 'src/app/services/file-upload.service';

@Component({
  selector: 'upload-card',
  templateUrl: './upload-card.component.html',
  styleUrls: ['./upload-card.component.scss']
})
export class UploadCardComponent implements OnInit {
  collapsed:boolean = true;
  filesUploaded: any = {
    "requestId": "8a7b9c00-1234-4d56-9abc-def012345678",
    "files": [
      { 
        "fileName": "file-5.csv", 
        "fileId": "773bd437-b636-4f8c-9438-39bf4dd383b2", 
        "status": "Malware Detected" 
      },
      {
        "fileName": "file-6.csv",
        "fileId": "d2275f65-d206-4987-b391-c1e41d36f061",
        "status": "File_Uploaded"
       }
    ]
  };

  success: number = 0;
  failed: number = 0;
  updating_array = UPLOADING_STATUS;
  

  constructor(private uploadService: FileUploadService) {
    // this.uploadService.currentFiles.subscribe(files => this.filesUploaded = files);
  }

  ngOnInit(): void {
    for (var file of this.filesUploaded.files) {
      if (file.status == 'File_Uploaded') {
        this.success++;
      } else if (!UPLOADING_STATUS.includes(file.status)) {
        this.failed++;
      }
    }
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
  }

  @HostBinding('class.is-expanded')
  get isExpanded() {
    return this.collapsed;
  }

  receiveFiles(files: File[]) {
    console.log("Files: ", this.filesUploaded);
    this.filesUploaded = files;
  }

  close() {
    document.getElementById("card").style.display = "none";
  }
}
